<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-custom gutter-b example example-compact">
        <div class="col-12" v-show="filter.show">
          <div class="row m-0">
            <div class="col-3">
              <input
                type="text"
                v-model="filter.form.contract__contract_number__icontains"
                v-debounce:400ms="onFilterSubmit"
                class="form-control"
                placeholder="Shartnoma raqami"
              />
            </div>

            <div class="col-3">
              <input
                type="text"
                v-model="filter.form.contract__client__full_name__icontains"
                v-debounce:400ms="onFilterSubmit"
                class="form-control"
                placeholder="Mijoz nomi"
              />
            </div>

            <div class="col-3">
              <v-dialog
                ref="dialog"
                v-model="menu.operDateFromMenu"
                :return-value.sync="filter.form.start_date_after"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <input
                    :value="filter.form.start_date_after | formatDate"
                    type="text"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    class="form-control"
                    placeholder="Sanadan"
                  />
                </template>
                <v-date-picker
                  v-model="filter.form.start_date_after"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menu.operDateFromMenu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      () => {
                        $refs.dialog.save(filter.form.start_date_after)

                        onFilterSubmit()
                      }
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </div>

            <div class="col-3">
              <v-dialog
                ref="dialog2"
                v-model="menu.operDateToMenu"
                :return-value.sync="filter.form.start_date_before"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <input
                    :value="filter.form.start_date_before | formatDate"
                    type="text"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    class="form-control"
                    placeholder="Sanagacha"
                  />
                </template>
                <v-date-picker v-model="filter.form.start_date_before" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menu.operDateToMenu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      () => {
                        $refs.dialog2.save(filter.form.start_date_before)

                        onFilterSubmit()
                      }
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </div>

            <div class="col-3">
              <a
                @click="onFilterFormReset"
                class="btn btn-text-primary btn-hover-light-primary font-weight-bold mx-2"
                style="height: 40px"
              >
                Tozalash
              </a>
            </div>
          </div>
        </div>

        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">{{ $t('BREADCRUMBS.DONE_WORKS') }}</h3>
          </div>

          <div class="ml-auto d-flex align-items-center">
            <div class="d-flex align-items-center">
              <a
                @click="filter.show = !filter.show"
                class="btn btn-warning font-weight-bolder mr-2"
                style="height: 40px"
              >
                <span class="svg-icon svg-icon-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        opacity="0.3"
                      />
                      <path
                        d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                      />
                    </g></svg
                ></span>

                {{ $t('BUTTONS.FILTER') }}</a
              >

              <el-tooltip
                class="item"
                effect="dark"
                content="'Excel' га чиқариш"
                placement="top-start"
              >
                <el-button
                  class="t-btn"
                  style="
                    min-width: 5rem;
                    background-color: #01a39b;
                    color: white;
                  "
                  :loading="isLoading"
                  @click="handleDownloadExcel"
                >
                  <!-- <svg-icon style="fill: white" icon-class="excel" /> -->
                  {{ isLoading ? 'Yuklanyapti...' : 'Excel' }}
                </el-button>
              </el-tooltip>
            </div>
          </div>
        </div>

        <div class="card-body">
          <v-data-table
            :headers="headers"
            :items="allZayavka2"
            :loading="isLoading"
            @click:row="rowClick"
            item-key="id"
            single-select
            hide-default-footer
            no-data-text="Malumot kiritilmagan"
            class="elevation-1 row-pointer fixed-header"
          >
            <template v-slot:[`item.payment_type_name`]="{ item }">
              <div class="dd payment__name" :class="item.payment_text_color">
                <span
                  :class="[item.payment_color_label]"
                  class="label label-dot"
                ></span>
                {{ item.payment_type_name }}
              </div>
            </template>
            <template v-slot:[`item.item`]="{ item }">
              <!-- {{ item }} -->
              <span
                v-for="(dest, index) in item.direction_cities"
                :key="dest.id"
              >
                {{ dest.city_name.name }}
                <span v-if="index == item.direction_cities.length - 1"></span>
                <span v-else>-</span>
              </span>
            </template>
            <template v-slot:[`item.start_date_new`]="{ item }">
              {{ item.start_date_new | formatDate }}
            </template>
            <template v-slot:[`item.zayavka_status_name`]="{ item }">
              <div class="status__name" :class="item.status_style">
                {{ item.zayavka_status_name }}
              </div>
            </template>
          </v-data-table>
          <div class="text-left mt-5">
            <v-pagination
              :value="$_page"
              :total-visible="5"
              @input="onPageChange"
              :length="Math.ceil(countjob.count / 10)"
            ></v-pagination>
            <div class="row justify-content-end mt-2">
              <div class="mr-4" v-if="countjob.count">
                <p
                  style="font-size: 15px"
                  class="text-dark font-bold label label-lg label-light-success label-inline"
                >
                  Soni: {{ countjob.count }}
                </p>
              </div>
              <div>
                <p
                  style="font-size: 15px"
                  class="text-dark font-bold label label-lg label-light-success label-inline"
                >
                  Summa(NDS siz):{{
                    (allTotalJobsSummWitoutNds &&
                      allTotalJobsSummWitoutNds.toLocaleString('zh-ZH')) ||
                    ''
                  }}
                </p>
                <br />
                <p
                  style="font-size: 15px"
                  class="text-dark font-bold label label-lg label-light-success label-inline"
                >
                  Summa(NDS bilan):{{
                    (allTotalJobsSumm &&
                      allTotalJobsSumm.toLocaleString('zh-ZH')) ||
                    ''
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
// import ZayavkaAction from "@/view/content/dropdown/ZayavkaAction.vue";
import { takeFilledKeyValue } from '@/utils'
import { mapActions } from 'vuex'
import paginationMixin from '@/mixins/pagination.mixin.js'

export default {
  data() {
    return {
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      itemsPerPage: 10,
      page: 1,

      // items: ['Foo', 'Bar', 'Fizz', 'Buzz'],

      filter: {
        show: false,

        form: {
          contract__contract_number__icontains: '',
          contract__client__full_name__icontains: '',
          start_date_before: '',
          start_date_after: ''
        }
      },

      menu: {
        operDateFromMenu: false,
        operDateToMenu: false
      }
    }
  },
  // beforeCreate() {
  //   this.$store.dispatch('getDoneWorks')
  // },

  mixins: [paginationMixin],

  mounted() {
    this.setBreadcrumb([
      { title: this.$t('BREADCRUMBS.SOLD') },
      { title: this.$t('BREADCRUMBS.DONE_WORKS') }
    ])
  },
  computed: {
    headers() {
      return [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: this.$t('TABLE_HEADER.CLIENT'),
          value: 'zayavka_owner'
        },
        {
          text: this.$t('TABLE_HEADER.CONTRACT_NUMBER'),
          value: 'contract_number'
        },
        {
          text: this.$t('TABLE_HEADER.PROVIDER'),
          value: 'deliver'
        },
        {
          text: this.$t('TABLE_HEADER.PRICE'),
          value: 'price_with_nds'
        },
        {
          text: 'Boshlanish',
          value: 'start_date_new'
        },
        {
          value: 'item',
          text: this.$t('TABLE_HEADER.DIRECTION')
        },
        {
          text: this.$t('TABLE_HEADER.STATUS'),
          value: 'zayavka_status_name'
        },

        {
          value: 'actions',
          text: '',
          sortable: false
        }
      ]
    },
    allZayavka2() {
      return this.$store.state.requests.doneWorks.results
    },
    allTotalJobsSumm() {
      return this.$store.state.requests.doneWorks.price_with_nds_total
    },
    allTotalJobsSummWitoutNds() {
      return this.$store.state.requests.doneWorks.price_total
    },
    countjob() {
      return this.$store.state.requests.doneWorks
    },

    isLoading() {
      return this.$store.state.requests.isLoading
    }
  },
  methods: {
    ...mapActions(['getDoneWorksWithPage', 'setBreadcrumb']),

    onPageChange(pageNumber) {
      this.$router
        .push({
          query: {
            ...this.$route.query,
            page: pageNumber
          }
        })
        .catch(() => {
          this.getPostData()
        })
    },

    rowClick(item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        row.select(false)
      }
    },

    getPostData() {
      const filledProps = takeFilledKeyValue(this.filter.form)

      this.getDoneWorksWithPage({
        ...(filledProps || {}),
        page: this.$_page,
        limit: this.$_pageOffset
      })
    },

    handleDownloadExcel() {
      //todo excel download
    },

    onFilterSubmit() {
      this.$router
        .push({
          query: {
            ...this.$route.query,
            page: 1
          }
        })
        .catch(() => {
          this.getPostData()
        })
    },

    onFilterFormReset() {
      this.filter.form = {}
      //todo fetch
      this.getPostData()
    }
  },

  watch: {
    $route: {
      handler: 'getPostData',
      immediate: true
    }
  }
}
</script>

<style scoped>
.status__name {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 1px 4px;
}
.payment__name {
  font-weight: bold;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: unset !important;
  overflow-y: unset !important;
}
</style>

<style>
.v-application .fixed-header {
  height: 500px !important;
  overflow-y: scroll !important;
}

.fixed-header.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  background-color: #fff;
  position: sticky !important;
  top: -1px !important;
}

.card.card-custom > .card-header {
  justify-content: flex-start;
}
</style>
